<template>
  <div class="make">
    <img
      src="https://cdn.bubbleplan.cn/static/marketplace/Frame9617.png"
      alt=""
      class="make-back"
      @click="back()"
    />
    <div class="make-content">
      <div class="make-content-stair" @click="recordButton(0)">转赠记录</div>
      <div class="make-content-stair1" @click="recordButton(1)">接收记录</div>
      <div class="make-content-box">
        <div class="make-content-box-title flex">
          <img
            src="https://cdn.bubbleplan.cn/m_bubble/make/Group10555.png"
            alt=""
            class="make-content-box-title-img"
            @click="transferableButton()"
          />
          <div>可转赠物品</div>
        </div>
        <div class="make-content-box-text">接收人UID</div>
        <div class="make-content-box-input1 flex items-center">
          <el-input placeholder="请输入接收人UID" v-model="forUserId">
          </el-input>
        </div>
        <div class="make-content-box-text marginTop35">选择转赠的物品</div>
        <div class="make-content-box-list flex">
          <div
            v-for="(item, index) in transferProductNumList"
            :key="index"
            class="make-content-box-list-li"
            :class="makeListIndex == index ? 'make-content-box-list-li1' : ''"
            @click="makeListIndexButton(index)"
          >
            <img
              :src="item.productImg"
              mode=""
              class="make-content-box-list-li-img"
            />
          </div>
        </div>
        <div class="make-content-box-button flex">
          <div class="make-content-box-button-but" @click="abolish()">取消</div>
          <div
            class="make-content-box-button-but1"
            @click="makedetailsButton()"
          >
            确认
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      forUserId: "",
      transferProductNumList: [],
      makeListIndex: 0,
      categoryId: "",
    };
  },
  mounted() {
    this.transferProductNum();
    if (this.$route.query.categoryId) {
      this.categoryId = this.$route.query.categoryId;
    }
  },

  methods: {
    abolish() {
      this.forUserId = "";
    },
    makeListIndexButton(index) {
      this.makeListIndex = index;
    },
    async transferProductNum() {
      const { data, code } = await this.$ajax.transferProductNum();
      this.transferProductNumList = data;
      if (this.categoryId) {
        this.transferProductNumList.forEach((item, index) => {
          if (item.categoryId == this.categoryId) {
            this.makeListIndex = index;
          }
        });
      }
    },
    back() {
      this.$router.push(`/home`);
    },
    recordButton(type) {
      this.$router.push(`/home/make/record?type=${type}`);
    },
    transferableButton() {
      this.$router.push(`/home/make/transferable`);
    },
    async makedetailsButton() {
      if (!this.forUserId) {
        this.$message({
          message: "请输入接收人UID",
          type: "warning",
        });
        return;
      }

      const { data, code } = await this.$ajax.getUserInfoByTransfer({
        userId: this.forUserId,
      });
      if (code == 200) {
        this.$router.push(
          `/home/make/makedetails?categoryId=${
            this.transferProductNumList[this.makeListIndex].categoryId
          }&productName=${
            this.transferProductNumList[this.makeListIndex].productName
          }&forUserId=${this.forUserId}&name=${data.name}`
        );
        this.forUserId = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.make {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/make/make.png") no-repeat;
  background-size: 100% 100%;
  //   padding: 60px 0 0 330px;
  display: flex;
  justify-content: center;
  padding-top: 66px;
  overflow: auto;
  position: relative;
  &-back {
    position: absolute;
    top: 66px;
    left: 100px;
  }
  &-back:hover {
    cursor: pointer;
  }

  &-content {
    width: 1054px;
    height: 730px;
    background: url("https://cdn.bubbleplan.cn/static/make/Group10447.png")
      no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding: 43px 56px;
    &-stair:hover {
      cursor: pointer;
    }
    &-stair1:hover {
      cursor: pointer;
    }
    &-stair {
      width: 144px;
      height: 64px;
      background: url("https://cdn.bubbleplan.cn/static/make/Vector906.png")
        no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 91px;
      right: -61px;
      text-align: center;
      line-height: 64px;
      color: #fef5de;
      font-family: "TsangerYuMo1";
      font-size: 22px;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
        1px 1px 0 #000;

      /* 模拟描边效果 */
      padding-left: 14px;
    }
    &-stair1 {
      width: 150px;
      height: 61px;
      background: url("https://cdn.bubbleplan.cn/static/make/Vector907.png")
        no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 167px;
      right: -50px;
      text-align: center;
      line-height: 61px;
      color: #fef5de;
      font-family: "TsangerYuMo1";
      font-size: 22px;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
        1px 1px 0 #000;

      /* 模拟描边效果 */
      padding-left: 34px;
    }
    &-box {
      width: 942px;
      height: 623px;
      background: url("https://cdn.bubbleplan.cn/static/make/Vector547.png")
        no-repeat;
      background-size: 100% 100%;
      position: absolute;
      padding: 35px 40px;
      &-title {
        color: #fef5de;
        font-family: "TsangerYuMo1";
        font-size: 26px;
        text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
          2px 2px 0 #000;

        /* 模拟描边效果 */
        align-items: center;
        &-img:hover {
          cursor: pointer;
        }
        &-img {
          width: 29px;
          height: 30px;
          margin-right: 7.59px;
        }
      }
      &-text {
        margin-top: 23.35px;
        text-align: center;
        font-size: 24px;
        font-family: "TsangerYuMo1";
        color: #fef5de;
        text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
          2px 2px 0 #000;
        /* 模拟描边效果 */
      }
      &-input1 {
        margin-top: 13px;
        :deep(.el-input) {
          height: 63px;
          //   padding: 13px 23px !important;
          background: url("https://cdn.bubbleplan.cn/static/make/Vector775.png")
            no-repeat;
          background-size: 100% 100%;
          font-size: 20px;
          color: #e6d9d9;
          font-weight: 700;
          text-align: center;
          line-height: 63px;
        }
        :deep(.el-input__inner) {
          background: transparent !important;
          border: none !important;
          text-align: center;
          color: #fff;
          line-height: 63px;
        }
        :deep(.el-input input)::placeholder {
          color: #ffffffcc;
          text-align: center;
          line-height: 63px;
        }
      }
      .marginTop35 {
        margin-top: 35px;
      }
      &-list {
        justify-content: center;
        &-li1 {
          background: url("https://cdn.bubbleplan.cn/m_bubble/make/Frame9635.png")
            no-repeat;
          background-size: 100% 100%;
        }
        &-li:hover {
          cursor: pointer;
        }
        &-li {
          width: 165px;
          height: 165px;
          display: flex;
          align-items: center;
          justify-content: center;
          &-img {
            width: 104px;
            height: 104px;
          }
        }
      }
      &-button {
        margin-top: 148px;
        justify-content: center;
        &-but:hover {
          cursor: pointer;
        }
        &-but1:hover {
          cursor: pointer;
        }
        &-but {
          width: 193px;
          height: 61px;
          background: url("https://cdn.bubbleplan.cn/static/make/Frame9619.png")
            no-repeat;
          background-size: 100% 100%;
          color: #f1eae2;
          font-family: "TsangerYuMo1";
          font-size: 26px;
          text-shadow: -3px -3px 0 #533690, 3px -3px 0 #533690,
            -3px 3px 0 #533690, 3px 3px 0 #533690;
          /* 模拟描边效果 */
          line-height: 61px;
          text-align: center;
        }

        &-but1 {
          width: 193px;
          height: 61px;
          background: url("https://cdn.bubbleplan.cn/static/make/Frame9618.png")
            no-repeat;
          background-size: 100% 100%;
          font-size: 26px;
          font-family: "TsangerYuMo1";
          color: #f1eae2;
          text-shadow: -3px -3px 0 #533690, 3px -3px 0 #533690,
            -3px 3px 0 #533690, 3px 3px 0 #533690;
          /* 模拟描边效果 */
          line-height: 61px;
          text-align: center;
        }
      }
    }
  }
}
</style>