<template>
  <div class="transferable">
    <img
      src="https://cdn.bubbleplan.cn/static/marketplace/Frame9617.png"
      alt=""
      class="transferable-back"
      @click="back()"
    />
    <div class="transferable-content flex">
      <div
        class="transferable-content-t flex"
        v-for="(item, index) in transferProductNumList"
        :key="index"
        @click="choiceButton(item, index)"
      >
        <div class="flex" style="align-items: center">
          <img
            :src="item.productImg"
            mode=""
            class="transferable-content-t-img"
          />
          <div class="transferable-content-title">{{ item.productName }}</div>
        </div>

        <div
          style="width: 30%; align-items: center; justify-content: center"
          class="flex"
        >
          <div class="transferable-content-t-num1">{{ item.count }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      transferProductNumList: [],
    };
  },
  mounted() {
    this.transferProductNum();
  },
  methods: {
    choiceButton(item, index) {
      this.$router.push(`/home/make/make?categoryId=${item.categoryId}`);
    },
    async transferProductNum() {
      const { data, code } = await this.$ajax.transferProductNum();
      this.transferProductNumList = data;
    },
    back() {
      history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.transferable {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/make/make.png") no-repeat;
  background-size: 100% 100%;
  //   padding: 60px 0 0 330px;
  display: flex;
  justify-content: center;
  padding-top: 51px;
  overflow: auto;
  position: relative;
  &-back {
    position: absolute;
    top: 66px;
    left: 100px;
  }
  &-back:hover {
    cursor: pointer;
  }
  &-content {
    width: 1054px;
    height: 750px;
    background: url("https://cdn.bubbleplan.cn/static/make/Group11072.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 109px 117px;
    flex-wrap: wrap;
    align-content: flex-start;
    &-t:hover {
      cursor: pointer;
    }
    &-t {
      width: 391px;
      height: 103px;
      background: url("https://cdn.bubbleplan.cn/m_bubble/make/Group10937.png")
        no-repeat;
      background-size: 100% 100%;
      padding-left: 5px;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 26px;
      margin-right: 18px;
      &-img {
        width: 77px;
        height: 77px;
      }

      &-num1 {
        position: absolute;
        color: #ffffff;
        font-family: "TsangerYuMo1";
        font-size: 26px;
        text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
          2px 2px 0 #000;
        // line-height: 80rpx;
      }
    }

    &-title {
      color: #ffffff;
      font-family: "TsangerYuMo1";
      font-size: 24px;
      margin-left: 43px;
      text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
        2px 2px 0 #000;

      /* 模拟描边效果 */
    }
  }
}
</style>