<template>
  <div class="makedetails">
    <img
      src="https://cdn.bubbleplan.cn/static/marketplace/Frame9617.png"
      alt=""
      class="makedetails-back"
      @click="back()"
    />
    <div class="makedetails-content">
      <img :src="titleImg" alt="" class="makedetails-content-text" />
      <div class="makedetails-content-title margin30">接收人UID</div>
      <div class="makedetails-content-input1 flex items-center">
        <el-input
          placeholder="请输入接收人UID"
          v-model="particulars.forUserId"
          :disabled="true"
        >
        </el-input>
      </div>
      <div class="makedetails-content-title margin30">对方昵称</div>
      <div class="makedetails-content-input1 flex items-center">
        <el-input
          placeholder="请输入接收人UID"
          v-model="particulars.name"
          :disabled="true"
        >
        </el-input>
      </div>
      <div class="makedetails-content-title margin30">选择数量</div>
      <template v-if="particulars.categoryId == '1296'">
        <div
          class="makedetails-content-input1 flex items-center marginButtom225"
        >
          <el-input placeholder="请输入数量" v-model="transferCount">
          </el-input>
        </div>
      </template>
      <template v-else>
        <div class="makedetails-content-box">
          <div
            class="flex"
            style="align-items: center; justify-content: space-between"
          >
            <div>请输入数量：</div>
            <el-input
              placeholder="请输入数量"
              border="surround"
              v-model="transferCount"
            ></el-input>
          </div>
          <div
            class="flex marginTop23"
            style="align-items: center; justify-content: space-between"
          >
            <div>转赠手续费：</div>
            <div>消耗{{ servicecharge }}{{ particulars.productName }}积分</div>
          </div>
          <div
            class="flex marginTop23"
            style="
              align-items: center;
              justify-content: space-between;
              m
            "
          >
            <div>到账数量：</div>
            <div>{{ transferCount }}</div>
          </div>
        </div>
      </template>
      <div class="makedetails-content-button flex">
        <div class="makedetails-content-button-but">取消</div>
        <div
          class="makedetails-content-button-but1"
          @click="makedetailsButton()"
        >
          确认
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      titleImg: "",
      particulars: {},
      transferCount: "",
    };
  },
  mounted() {
    this.particulars = this.$route.query;
    if (this.particulars.categoryId == "1296") {
      this.titleImg = "https://cdn.bubbleplan.cn/static/make/Group10235.png";
    } else if (this.particulars.categoryId == "29") {
      this.titleImg = "https://cdn.bubbleplan.cn/static/make/Group11063.png";
    } else {
      this.titleImg = "https://cdn.bubbleplan.cn/static/make/Group102351).png";
    }
  },
  computed: {
    servicecharge() {
      if (this.transferCount) {
        return Math.round(this.transferCount * 10 * 0.07 * 100) / 100;
      } else {
        return 0;
      }
    },
  },
  methods: {
    async makedetailsButton() {
      if (!this.transferCount) {
        this.$message({
          message: "请输入转赠数量",
          type: "warning",
        });
        return;
      }
      let integer = /^(?:0|(?:-?[1-9]\d*))$/;
      if (!integer.test(this.transferCount)) {
        this.$message({
          message: "请输入正确数量",
          type: "warning",
        });
        return;
      }

      const params = {
        categoryId: this.particulars.categoryId,
        forUserId: this.particulars.forUserId,
        transferCount: this.transferCount,
      };
      const { data, code } = await this.$ajax.doTransfer(params);
      if (code == 200) {
        this.transferCount = "";
        this.userNum();
      }
    },
    //查询积分金币
    async userNum() {
      const { data, code } = await this.$ajax.userNum();
      if (code == 200) {
        this.userProperty(data);
        this.crystalNum();
      }
    },
    //查询晶体or晶体积分
    async crystalNum() {
      const { data, code } = await this.$ajax.crystalNum();
      if (code == 200) {
        this.usercrystalNumData(data);
      }
    },
    cancel() {
      this.transferCount = "";
      this.particulars = {};
      history.back();
    },
    back() {
      history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.makedetails {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/make/make.png") no-repeat;
  background-size: 100% 100%;
  //   padding: 60px 0 0 330px;
  display: flex;
  justify-content: center;
  padding-top: 66px;
  overflow: auto;
  position: relative;
  &-back {
    position: absolute;
    top: 66px;
    left: 100px;
  }
  &-back:hover {
    cursor: pointer;
  }
  &-content {
    width: 1054px;
    height: 730px;
    background: url("https://cdn.bubbleplan.cn/static/make/Group11077.png")
      no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding: 22px 152px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      margin-top: 57px;
      color: #fef5de;
      font-family: "TsangerYuMo1";
      font-size: 24px;
      text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
        2px 2px 0 #000;

      /* 模拟描边效果 */
    }
    &-input1 {
      width: 100%;
      margin-top: 13px;
      :deep(.el-input) {
        height: 63px;
        //   padding: 13px 23px !important;
        background: url("https://cdn.bubbleplan.cn/static/make/Vector775.png")
          no-repeat;
        background-size: 100% 100%;
        font-size: 20px;
        color: #e6d9d9;
        font-weight: 700;
        text-align: center;
        line-height: 63px;
      }
      :deep(.el-input__inner) {
        background: transparent !important;
        border: none !important;
        text-align: center;
        color: #fff;
        line-height: 63px;
      }
      :deep(.el-input input)::placeholder {
        color: #ffffffcc;
        text-align: center;
        line-height: 63px;
      }
    }
    .margin30 {
      margin-top: 30px !important;
    }
    .marginButtom225 {
      margin-bottom: 140px;
    }
    &-box {
      margin-top: 13px;
      width: 583px;
      height: 190px;
      background: url("https://cdn.bubbleplan.cn/static/make/Vector795.png")
        no-repeat;
      background-size: 100% 100%;
      padding: 29px 30px;
      margin-bottom: 28px;
      color: #e6d9d9;
      font-family: "TsangerYuMo1";
      font-size: 22px;
      :deep(.el-input) {
        width: 200px !important;
      }
      :deep(.el-input__inner) {
        background-color: transparent !important;
        border: none !important;
        text-align: right;
        color: #e6d9d9;
        font-family: "TsangerYuMo1";
        font-size: 22px;
      }
    }
    .marginTop23 {
      margin-top: 23px;
    }
    &-button {
      justify-content: center;
      &-but:hover {
        cursor: pointer;
      }
      &-but1:hover {
        cursor: pointer;
      }
      &-but {
        width: 193px;
        height: 61px;
        background: url("https://cdn.bubbleplan.cn/static/make/Frame9619.png")
          no-repeat;
        background-size: 100% 100%;
        color: #f1eae2;
        font-family: "TsangerYuMo1";
        font-size: 26px;
        text-shadow: -3px -3px 0 #533690, 3px -3px 0 #533690, -3px 3px 0 #533690,
          3px 3px 0 #533690;
        /* 模拟描边效果 */
        line-height: 61px;
        text-align: center;
      }

      &-but1 {
        width: 193px;
        height: 61px;
        background: url("https://cdn.bubbleplan.cn/static/make/Frame9618.png")
          no-repeat;
        background-size: 100% 100%;
        font-size: 26px;
        font-family: "TsangerYuMo1";
        color: #f1eae2;
        text-shadow: -3px -3px 0 #533690, 3px -3px 0 #533690, -3px 3px 0 #533690,
          3px 3px 0 #533690;
        /* 模拟描边效果 */
        line-height: 61px;
        text-align: center;
      }
    }
  }
}
</style>