<template>
  <div class="record">
    <img
      src="https://cdn.bubbleplan.cn/static/marketplace/Frame9617.png"
      alt=""
      class="record-back"
      @click="back()"
    />
    <div class="record-box">
      <div class="record-box-list">
        <div class="record-box-list-title">
          {{ type == 0 ? "转赠记录" : "接收记录" }}
        </div>
        <div class="record-box-list-type flex">
          <div
            v-for="(item, index) in typeList"
            :key="index"
            class="record-box-list-type-li"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="record-box-list-li">
          <div style="height: 100%" @scroll="handleScroll1">
            <div
              v-for="(item, index) in transferLogList"
              :key="index"
              class="record-box-list-li-l flex"
            >
              <div class="record-box-list-li-l-text">
                {{ type == 0 ? item.forUserId : item.fromUserId }}
              </div>
              <div class="record-box-list-li-l-text">
                {{ item.name }}*{{ item.num }}
              </div>
              <div class="record-box-list-li-l-text">{{ item.createTime }}</div>
              <div class="record-box-list-li-l-text">成功</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: 0,
      typeList: [
        {
          name: "UID",
        },
        {
          name: "物品",
        },
        {
          name: "接收时间",
        },
        {
          name: "状态",
        },
      ],
      show: false,
      page: {
        pageNum: 1,
        pageSize: 15,
      },
      transferLogList: [],
      hasNextPage: false,
    };
  },
  mounted() {
    this.type = this.$route.query.type;
    if (this.type == 0) {
      this.typeList[2].name = "接收时间";
    } else {
      this.typeList[2].name = "转赠时间";
    }
    this.transferLog();
  },
  methods: {
    async transferLog(type) {
      const params = {
        ...this.page,
        type: this.type,
      };
      const { data, code } = await this.$ajax.transferLog(params);
      if (type == "bottom") {
        this.transferLogList.push(...data.list);
      } else {
        this.transferLogList = data.list;
      }
      this.hasNextPage = data.hasNextPage;
    },
    handleScroll1() {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.hasNextPage) {
          this.page.pageNum++;
          this.transferLog("bottom");
        }
      }
    },

    back() {
      history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.record {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/make/make.png") no-repeat;
  background-size: 100% 100%;
  //   padding: 60px 0 0 330px;
  display: flex;
  justify-content: center;
  padding-top: 66px;
  overflow: auto;
  position: relative;
  &-back {
    position: absolute;
    top: 66px;
    left: 100px;
  }
  &-back:hover {
    cursor: pointer;
  }
  &-box {
    &-list {
      width: 1054px;
      height: 750px;
      background: url("https://cdn.bubbleplan.cn/static/make/Group1107-1.png")
        no-repeat;
      background-size: 100% 100%;
      overflow: auto;
      padding: 17px 107px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 50px;
      &-title {
        font-family: "TsangerYuMo1";
        font-size: 26px;
        color: #ffffff;
        text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
          2px 2px 0 #000;

        /* 模拟描边效果 */
        text-align: center;
      }

      &-type {
        width: 841px;
        height: 59px;
        background: url("https://cdn.bubbleplan.cn/static/make/Vector565.png")
          no-repeat;
        background-size: 100% 100%;
        margin-top: 54px;
        line-height: 59px;
        font-size: 18px;
        font-weight: Bold;
        &-li {
          width: 25%;
          color: #d9d2e4;
          font-size: 18px;
          text-align: center;
          line-height: 59px;
        }
      }

      &-li {
        width: 100%;
        // flex: 1;
        overflow: auto;
        &-l:nth-child(2n) {
          background: url("https://cdn.bubbleplan.cn/static/make/Vector565.png")
            no-repeat;
          background-size: 100% 100%;
          color: #d9d2e4;
        }

        &-l {
          width: 100%;
          height: 59px;
          color: #4e497d;

          &-text {
            width: 25%;
            text-align: center;
            font-size: 18px;
            line-height: 59px;
          }
        }
      }
    }
  }
}
</style>