<template>
  <div class="individualAccount-height">
    <div class="individualAccount-right-type flex">
      <div
        v-for="(item, index) in typeList"
        :key="index"
        class=""
        :class="
          classifyIndex == index
            ? 'individualAccount-right-type-li1'
            : 'individualAccount-right-type-li'
        "
        @click="handleSelect(index)"
      >
        {{ item.name }}
        <template v-if="classifyIndex > 0 && secondlevel">
          <template v-if="typeList[classifyIndex].children">
            <div
              class="secondarySelection"
              v-if="typeList[classifyIndex] && classifyIndex == index"
            >
              <div
                v-for="(l, w) in typeList[classifyIndex].children"
                :key="w"
                :style="
                  secondIndex == w
                    ? 'border: 1.51px solid #A48BBD;background: #DDD6E8;'
                    : 'border: 1.51px solid #A48BBD;background: #FFF;'
                "
                @click.stop="secondarySelection(l, w)"
              >
                {{ l.name }}
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
    <div class="individualAccount-right-commodity flex" @scroll="handleScroll">
      <div
        v-for="(item, index) in commodityList"
        :key="index"
        class="individualAccount-right-commodity-li"
        @click="commodityButton(item)"
      >
        <div class="individualAccount-right-commodity-li-text">
          {{ item.productName }}
        </div>
        <div class="individualAccount-right-commodity-li-imgbac">
          <img
            :src="item.productImg"
            alt=""
            class="individualAccount-right-commodity-li-img"
          />
        </div>
        <div
          class="individualAccount-right-commodity-li-button"
          @click.stop="openButton(item)"
          v-if="
            item.categoryId == 70 ||
            item.categoryId == 102 ||
            item.categoryId == 103 ||
            item.categoryId == 104 ||
            item.categoryId == 105
          "
        >
          开启
        </div>
        <div
          class="individualAccount-right-commodity-li-button"
          @click.stop="activate(item)"
          v-if="item.categoryId == 1296"
        >
          激活
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="30%"
      :show-close="false"
    >
      <div class="commodityDetails">
        <div class="commodityDetails-img">
          <img
            src="https://cdn.bubbleplan.cn/static/marketplace/X.png"
            alt=""
            class="commodityDetails-img-ii"
            @click="dialogVisible = !dialogVisible"
          />
        </div>
        <div class="popupClass-top flex">
          <div class="popupClass-top-imgbgc">
            <img
              :src="productpictureDate.productImg"
              mode=""
              class="popupClass-top-img"
            />
          </div>
          <div class="flex popupClass-top-text flex-direction flex-between">
            <div class="popupClass-top-text-t">
              {{ productpictureDate.productTypeName }}
            </div>
            <div class="popupClass-top-text-num">
              数量：<span class="popupClass-top-text-num-span">{{
                count
              }}</span>
            </div>
          </div>
        </div>
        <div
          style="
            width: 100%;
            height: 2px;
            background: rgba(87, 67, 82, 0.3);
            margin-top: 36px;
            margin-bottom: 17px;
          "
        ></div>
        <div class="commodityDetails-introduce">
          {{ productpictureDate.productDescribe }}
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="openShow"
      width="26%"
      top="200px"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="openClass">
        <template v-if="openIst">
          <div class="openClass-true">
            <img
              :src="
                'https://cdn.bubbleplan.cn/m_bubble/marketplace/20241106-191632.gif?' +
                new Date().getTime()
              "
              mode=""
              class="openClass-true-img"
            />
          </div>
        </template>
        <template v-else>
          <div class="openClass-false">
            <img
              class="openClass-false-back"
              src="https://cdn.bubbleplan.cn/m_bubble/marketplace/X2x.png"
              mode=""
              @click="openClose()"
            />
            <div class="openClass-false-title">恭喜获得</div>
            <div
              class="openClass-false-ul flex"
              :class="openBox.categoryId == 70 ? '' : 'marginTop'"
              style="justify-content: space-between; flex-wrap: wrap"
            >
              <div
                v-for="(item, index) in trophyData"
                :key="index"
                class="openClass-false-ul-li"
              >
                <img
                  :src="openBox.categoryId == 70 ? item.img : item.productImg"
                  mode=""
                  class="openClass-false-ul-li-img"
                />

                <div class="openClass-false-ul-li-text">
                  {{
                    openBox.categoryId == 70
                      ? "×" + item.count
                      : item.productName
                  }}
                </div>
              </div>
            </div>

            <div class="openClass-false-button" @click="openClose()">确认</div>
          </div>
        </template>
      </div>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="activateShow"
      width="26%"
      top="200px"
      :show-close="false"
      :before-close="activateClose"
    >
      <div class="activate">
        <div class="activate-title">激活</div>
        <img
          src="https://cdn.bubbleplan.cn/m_bubble/marketplace/X2x.png"
          mode=""
          class="activate-img"
          @click="activateClose()"
        />
        <div class="activate-input">需要激活的数量</div>
        <div class="activate-input1">
          <el-input placeholder="请输入数量" v-model="num"> </el-input>
        </div>
        <div class="activate-text">
          当前可激活数量<span style="color: #d126b5">{{ cardCount }}</span>
        </div>
        <div class="activate-button flex">
          <div class="activate-button-left" @click="activateClose()">取消</div>
          <div class="activate-button-right" @click="activateBubbleCard()">
            确认
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      count: 0,
      dialogVisible: false,
      productpictureDate: {},
      categoryId: null,
      typeList: [],
      classifyIndex: 0,
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      categoryId: null,
      commodityList: [],
      hasNextPage: false,
      secondIndex: null,
      secondlevel: false,
      openShow: false,
      openIst: true,
      trophyData: {},
      openBox: {},
      activateShow: false,
      num: "",
      cardCount: 0,
    };
  },
  mounted() {
    this.getTypeList();
    // this.getLevelTwoTypeList();
  },
  methods: {
    // 激活弹窗
    activate() {
      this.activateShow = true;
      this.unActivatedBubbleCardCount();
    },
    //查询激活数量
    async unActivatedBubbleCardCount() {
      const { data, code } = await this.$ajax.unActivatedBubbleCardCount();
      this.cardCount = data;
    },
    //取消
    activateClose() {
      this.activateShow = false;
      this.num = "";
    },
    //激活
    async activateBubbleCard() {
      let integer = /^(?:0|(?:-?[1-9]\d*))$/;
      if (!integer.test(this.num)) {
        this.$message({
          message: "请输入正确数量",
          type: "warning",
        });
        return;
      }
      const { data, code } = await this.$ajax.activateBubbleCard({
        num: this.num,
      });
      if (code == 200) {
        this.activateClose();
        this.page.pageNum = 1;
        this.myBag();
      }
    },
    openClose() {
      if (!this.openIst) {
        this.openShow = false;

        setTimeout(() => {
          this.openIst = true;
        }, 500);
      }
    },
    //开启
    openButton(item) {
      this.openBox = item;
      if (
        item.categoryId == 102 ||
        item.categoryId == 103 ||
        item.categoryId == 104 ||
        item.categoryId == 105
      ) {
        this.openEquipmentBlindBox(item);
      } else {
        this.openNoviceGiftPack();
      }

      // this.openIst = true;
      // this.openShow = true;
      // setTimeout(() => {
      //   this.openIst = false;
      // }, 6000);
    },
    async openEquipmentBlindBox(item) {
      const params = {
        categoryId: item.categoryId,
      };
      const { data, code } = await this.$ajax.openEquipmentBlindBox(params);
      if (code == 200) {
        this.trophyData = data;
        this.openIst = true;
        this.openShow = true;
        setTimeout(() => {
          this.openIst = false;
          this.page.pageNum = 1;
          this.myBag();
        }, 5500);
      }
    },
    async openNoviceGiftPack() {
      const params = {};
      const { data, code } = await this.$ajax.openNoviceGiftPack(params);
      if (code == 200) {
        this.trophyData = data;
        this.openIst = true;
        this.openShow = true;
        setTimeout(() => {
          this.page.pageNum = 1;
          this.myBag();
          this.openIst = false;
        }, 5500);
      }
    },
    secondarySelection(item, index) {
      this.secondlevel = false;
      this.secondIndex = index;
      this.categoryId = item.categoryId;
      this.page.pageNum = 1;
      this.myBag();
    },
    //获取分类
    async getTypeList() {
      const { data, code } = await this.$ajax.getTypeList();
      let children = [
        {
          name: "全部",
          children: [],
        },
      ];
      let typeData = [];
      data.forEach((item) => {
        if (item.children) {
          item.children.forEach((l) => {
            children[0].children.push(l);
          });
          typeData.push(...item.children);
        }
      });
      typeData.forEach((l, i) => {
        if (l.name == "TOKEN") {
          if (l.children[0].name == "芯片") {
            l.name = "芯片";
          }
          if (l.children[0].name == "晶体") {
            l.name = "晶体";
          }
        }
      });
      this.typeList.push(...children, ...typeData);
      // this.categoryId = this.typeList[0].categoryId
      this.myBag();
    },
    async myBag(type) {
      const params = {
        ...this.page,
        parentId: this.categoryId,
      };
      const { data, code } = await this.$ajax.myBag(params);
      if (type == "bottom") {
        this.commodityList.push(...data.list);
      } else {
        this.commodityList = data.list;
      }
      this.hasNextPage = data.hasNextPage;
    },
    //查看详情
    async commodityButton(item) {
      this.count = item.count;
      const { data, code } = await this.$ajax.getTypeById({
        categoryId: item.categoryId,
      });
      if (code == 200) {
        this.productpictureDate = data;
        this.dialogVisible = true;
      }
    },
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.hasNextPage) {
          this.page.pageNum++;
          this.myBag("bottom");
        }
      }
    },
    // //获取分类
    // async getTypeList() {
    //   const { data, code } = await this.$ajax.getTypeList();
    //   this.typeList = data;
    // },
    // 获取商品分类
    async getLevelTwoTypeList() {
      const { data, code } = await this.$ajax.getLevelTwoTypeList();
      this.typeList = data;
      this.categoryId = this.typeList[0].categoryId;
      this.myBag();
    },
    //我的背包
    async myBag(type) {
      const params = {
        ...this.page,
        parentId: this.categoryId,
      };
      const { data, code } = await this.$ajax.myBag(params);
      if (type == "bottom") {
        this.commodityList.push(...data.list);
      } else {
        this.commodityList = data.list;
      }
      this.hasNextPage = data.hasNextPage;
    },
    handleSelect(key) {
      // if (this.secondlevel == false) {
      if (this.classifyIndex != key) {
        this.secondIndex = null;
      }
      this.classifyIndex = key;
      this.page.pageNum = 1;
      this.secondlevel = true;

      this.categoryId = this.typeList[this.classifyIndex].categoryId;
      this.myBag();
      // } else {
      //   this.secondlevel = false;
      // }
    },
  },
};
</script>



<style lang="scss" scoped>
.individualAccount-height {
  padding: 33px 28px;
  position: relative;
  :deep(.el-dialog__header) {
    padding: 0;
  }
  :deep(.el-dialog__body) {
    padding: 0;
  }
  :deep(.el-dialog) {
    background: transparent !important;
  }
  .commodityDetails {
    width: 696px;
    height: 429px;
    padding: 57px 53px;
    background: url("https://cdn.bubbleplan.cn/static/marketplace/Group10236.png")
      no-repeat;
    background-size: 100% 100%;
    padding-bottom: 35.49px;
    position: relative;
    &-img {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      top: -16px;
      right: -15px;
      &-ii:hover {
        cursor: pointer;
      }
      &-ii {
        width: 61px;
        height: 59px;
      }
    }
    .popupClass-top {
      &-img {
        width: 166px;
        height: 166px;
      }

      &-imgbgc {
        width: 166px;
        height: 166px;
        margin-right: 29.98px;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/Group97282x.png")
          no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-text {
        flex: 1;

        &-t {
          color: #574352;
          font-size: 23px;
        }

        &-num {
          margin-bottom: 8px;
          color: #574352;
          font-size: 23px;
          &-span {
            color: #da13d2;
            font-size: 23px;
            font-weight: 700;
          }
        }
      }
    }
    &-introduce {
      margin-top: 20px;
      width: 100%;
      color: #574352;
    }
    &-button:hover {
      cursor: pointer;
    }
    &-button {
      margin: auto;
      margin-top: 37px;
      width: 341px;
      height: 55px;
      background: url("https://cdn.bubbleplan.cn/static/marketplace/button_we.png")
        no-repeat;
      background-size: 100% 100%;
      color: #0e0e18;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 55px;
    }
  }
  .openClass {
    color: #ffff;
    &-true {
      width: 405px;
      height: 394px;
      background: url("https://cdn.bubbleplan.cn/m_bubble/marketplace/Group10878.png")
        no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &-img {
        width: 300px;
        height: 300px;
      }
    }

    &-false {
      width: 488px;
      min-height: 347px;
      background: url("https://cdn.bubbleplan.cn/m_bubble/marketplace/647-508.png")
        no-repeat;
      background-size: 100% 100%;
      position: relative;
      padding: 0 63px;
      padding-top: 20px;
      &-back:hover {
        cursor: pointer;
      }
      &-back {
        position: absolute;
        width: 49px;
        height: 48px;
        right: -7px;
        top: -3px;
      }

      &-title {
        color: #574352;
        text-align: center;
        font-family: "TsangerYuMo1";
        font-size: 25px;
      }
      .marginTop {
        margin-top: 62px;
      }
      .margin8 {
        margin-top: 20px;
      }
      &-ul {
        margin-top: 42px;

        &-li {
          width: 50%;

          &-img {
            width: 90px;
            height: 90px;
          }

          &-text {
            color: #ffffff;
            font-family: "TsangerYuMo1";
            font-size: 22.76px;
            text-shadow: -1px -1px 0 #341030, 1px -1px 0 #341030,
              -1px 1px 0 #341030, 1px 1px 0 #341030;
            /* 模拟描边效果 */
            margin-top: -40.8px;
            margin-left: 65px;
            z-index: 9;
            position: relative;
          }
        }
      }
      &-button:hover {
        cursor: pointer;
      }
      &-button {
        width: 143px;
        height: 54px;
        background: url("https://cdn.bubbleplan.cn/m_bubble/marketplace/Vector1316072x.png")
          no-repeat;
        background-size: 100% 100%;
        color: #f3e9d0;
        text-align: center;
        text-shadow: 0 2.29px 0 #00000040;
        font-family: "TsangerYuMo1";
        font-size: 22px;
        line-height: 52px;
        margin: auto;
        margin-top: 32px;
      }
    }
  }
}

ul::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
ul::-webkit-scrollbar-track {
  background: #f1f1f1; /* 设置轨道的背景颜色 */
}
ul::-webkit-scrollbar-thumb {
  background: #888; /* 设置滑块的背景颜色 */
}
ul::-webkit-scrollbar-thumb:hover {
  background: #555; /* 设置滑块在悬停状态下的背景颜色 */
}
.individualAccount-right-type::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.individualAccount-right-type::-webkit-scrollbar-thumb {
  background: #fff; /* 设置滑块的背景颜色 */
}
.individualAccount-right-type::-webkit-scrollbar-thumb:hover {
  background: #fff; /* 设置滑块在悬停状态下的背景颜色 */
}

.individualAccount-right-type {
  width: 969.41px;
  height: 86px;
  border-radius: 9.7px;
  background: #685c84;
  box-shadow: 0 3.88px 3.88px 0 #00000040 inset;
  overflow: auto;
  padding: 15px 19px;

  &-li:hover {
    cursor: pointer;
  }
  &-li1:hover {
    cursor: pointer;
  }
  &-li1 {
    padding: 10px 20px;
    background: url("https://cdn.bubbleplan.cn/static/marketplace/Group10493.png")
      no-repeat;
    background-size: 100% 100%;
    color: #2f2f2f;
    text-align: center;
    font-family: "TsangerYuMo";
    font-size: 22px;
    font-weight: bold;
    margin-left: 16px;
    white-space: nowrap;
  }
  &-li {
    padding: 10px 20px;
    background: url("https://cdn.bubbleplan.cn/static/marketplace/Group10494.png")
      no-repeat;
    background-size: 100% 100%;
    color: #2f2f2f;
    text-align: center;
    font-family: "TsangerYuMo";
    font-size: 22px;
    font-weight: bold;
    margin-left: 16px;
    white-space: nowrap;
  }
  .secondarySelection {
    width: 150px;
    position: absolute;
    z-index: 999;
    top: 95px;

    padding: 9px 10px;
    border-radius: 4.51px;
    // background: #22212c;
    // border: 2.25px solid #74737c;
    margin-left: -42px;
    text-align: center;
    font-size: 18px;
    line-height: 46px;
    color: #4f4357;
  }
  :deep(.el-menu-item) {
    position: relative;
  }
  :deep(.el-menu.el-menu--horizontal) {
    display: flex;
    overflow: auto;
    border-bottom: solid 0.005208rem #616161 !important;
  }
  :deep(.el-menu--horizontal) {
    background: transparent !important;
  }
  :deep(.el-menu--horizontal > .el-menu-item.is-active) {
    color: #fff !important;
    font-size: 20px;
    font-weight: 700;
    border-bottom: 0.010417rem solid #daff00;
  }
  :deep(.el-menu-item) {
    padding: 0 30px;
  }
  :deep(.el-menu-item):hover {
    background-color: transparent !important;
  }
}
.individualAccount-right-commodity::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.individualAccount-right-commodity {
  height: 551px;
  overflow: auto;
  margin-top: 42px;
  flex-wrap: wrap;
  padding-left: 15px;
  &-li:nth-child(4n) {
    margin-right: 0;
  }
  &-li:hover {
    cursor: pointer;
    // width: 221.86px;
    // height: 254.23px;
    // background: url("https://cdn.bubbleplan.cn/static/marketplace/pitchon_cc.png")
    //   no-repeat;
    // background-size: 100% 100%;
    // margin-bottom: 34.41px;
    // margin-right: 17.14px;
  }

  &-li {
    width: 220px;
    height: 303px;
    background: url("https://cdn.bubbleplan.cn/static/marketplace/Group10428.png")
      no-repeat;
    background-size: 100% 100%;
    margin-bottom: 29px;
    margin-right: 16px;
    padding-top: 35.9px;
    position: relative;
    &-button {
      width: 127px;
      height: 52px;
      background: url("https://cdn.bubbleplan.cn/m_bubble/marketplace/Rectangle34625665.png")
        no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: 14px;
      left: 50%;
      transform: translate(-50%);
      color: #ffffff;
      line-height: 52px;
      text-align: center;
      font-family: "TsangerYuMo1";
      font-size: 22px;
      text-shadow: -2px -2px 0 #341030, 2px -2px 0 #341030, -2px 2px 0 #341030,
        2px 2px 0 #341030;
      /* 模拟描边效果 */
    }

    &-imgbac {
      width: 100%;
      height: 185.45px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-img {
      // width: 161px;
      height: 161px;
    }
    &-text {
      color: #ffffff;
      text-align: center;
      font-family: "qiantuhouheiti";
      font-size: 31.04px;
    }
  }
}
.activate {
  width: 469px;
  height: 338px;
  background: url("https://cdn.bubbleplan.cn/m_bubble/make/Gr1243oup10236.png")
    no-repeat;
  background-size: 100% 100%;
  padding: 19px 71px;
  position: relative;

  &-title {
    color: #574352;
    text-align: center;
    font-family: "TsangerYuMo1";
    font-size: 25px;
  }
  &-img:hover {
    cursor: pointer;
  }
  &-img {
    width: 47px;
    height: 46px;
    position: absolute;
    right: -5px;
    top: 0px;
  }

  &-input {
    margin-top: 60px;
    color: #fef5de;
    font-size: 23px;
    text-align: center;
    font-family: "TsangerYuMo1";
    text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
      2px 2px 0 #000;

    /* 模拟描边效果 */
    margin-bottom: 13px;
  }
  &-input1 {
    :deep(.el-input) {
      height: 49px;
      //   padding: 13px 23px !important;
      background: url("https://cdn.bubbleplan.cn/static/make/Vector755.png")
        no-repeat;
      background-size: 100% 100%;
      font-size: 22px;
      color: #e6d9d9;
      font-weight: 700;
      text-align: center;
      line-height: 49px;
    }
    :deep(.el-input__inner) {
      background: transparent !important;
      border: none !important;
      text-align: center;
      color: #fff;
      line-height: 49px;
    }
    :deep(.el-input input)::placeholder {
      color: #ffffffcc;
      text-align: center;
      line-height: 49px;
    }
  }

  &-text {
    text-align: center;
    margin-top: 12px;
    color: #574352;
    font-size: 19px;
    font-weight: Bold;
  }

  &-button {
    text-align: center;
    line-height: 54px;
    color: #f3e9d0;
    font-family: "TsangerYuMo1";
    font-size: 23px;
    text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
      2px 2px 0 #000;

    /* 模拟描边效果 */
    margin-top: 44px;
    justify-content: space-evenly;
    &-left:hover {
      cursor: pointer;
    }
    &-right:hover {
      cursor: pointer;
    }
    &-left {
      width: 135px;
      height: 54px;
      background: url("https://cdn.bubbleplan.cn/m_bubble/make/Vector606.png")
        no-repeat;
      background-size: 100% 100%;
    }

    &-right {
      width: 135px;
      height: 54px;
      background: url("https://cdn.bubbleplan.cn/m_bubble/make/Vector607.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>